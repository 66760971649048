@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Work+Sans&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("/src/static/final_back.png");
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  background-color: #303030;
  color: #FFFFFF;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

footer {
  margin-top:calc(22% + 60px);
  bottom: 0;
}

.footer_container {
  display: flex;
  justify-content: space-between;
}

.powered_by_div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.powered_by_p {
  color: white;
  display: flex;
  background-color: #222222;
  text-align:center;
  padding-right: 10px;
}

.powered_by_logos{
  object-fit: contain;
  height: 40px;
  width: 45px;
  padding: 2px;
  padding-right: 5px;
}

.social_media_div {
  display: flex;
  justify-content: space-between;
}

.social_media_logos{
  object-fit: contain;
  height: 40px;
  width: 45px;
  padding: 2px;
}

.header_div {
  position: static;
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .footer_container {
    display: flex;
    flex-direction: column-reverse;
  }
  .social_media_div {
    display: flex;
    justify-content: space-evenly;
    max-width: 90%!important;
  }
  
  .social_media_logos{
    object-fit: contain;
    height: 40px;
    width: 45px;
    padding: 2px;
  }
  .connect_to_wallet_button{
    display: none!important;
  }
  .menuButton{
    margin-left: 0px;
  }
  .header_div {
    position: fixed;
    width: 100%;
  }
  .about_shatter_div {
    height: 80%;
    padding-bottom: 30px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 30px;
    padding: 20px;
    margin-bottom: 20px;
    text-align: center;
    margin-top: 150px!important;
    max-width: 80%!important;
  }
  .makeStyles-header-1{
    padding-left: 0px!important;
    padding-right: 0px!important;
  }
  .faq_div{
    max-width: 80%!important;
  }
  .roadmap_div{
    max-width: 80%!important;
    text-align: center;
  }
  .team_div{
    max-width: 80%!important;
  }
  .roadmap_left{
    align-self: center!important;
  }
  
  .roadmap_right{
    align-self: center!important;
  }

}
