.App {
  text-align: center;
}
body {
  overflow-x: hidden;
  /*font-family: "Times New Roman", Times, serif;*/
  font-size: large;
}

.about_shatter_div {
  height: 80%;
  padding-bottom: 30px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 30px;
  padding: 20px;
  margin-bottom: 50px;
  text-align: center;
  margin-top: 150px;
  max-width: 50%;
}

.roadmap_div {
  padding-bottom: 30px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 30px;
  padding: 20px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  max-width: 50%;
  min-width: 50%;
  text-align: center;
}

.roadmap_div h1{
  text-align: center;
}

.roadmap_left{
  align-self: flex-start;
}

.roadmap_right{
  align-self: flex-end;
}

.faq_div {
  padding-bottom: 30px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 30px;
  padding: 20px;
  margin-bottom: 50px;
  max-width: 50%;
}
.faq_div h1{
  text-align: center;
}

.team_div {
  padding-bottom: 30px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 30px;
  padding: 20;
  margin-bottom: 50px;
  text-align: center;
  max-width: 50%;
  min-width: 50%;
}

.team_div_inside {
  display: flex;
  justify-content: center;
  text-align: center;
}

.zoid {
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
}

.silli {
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
}
/* mint button */
.sc-hKgJUU{
  position: relative;
  top: 100px;
}